import i18next from 'i18next'
import Backend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend' // primary use cache
import HttpApi from 'i18next-http-backend' // fallback xhr load

window.i18nextInitialized = false

document.addEventListener('DOMContentLoaded', (): void => {

  let curLng = document.body.dataset.lng
  if (curLng == null) {
    curLng = 'en'
  }

  const versions: any = {}

  versions[curLng] = document.body.dataset.lngV || null

  i18next
    .use(Backend)
    .init({
      lng: curLng,
      debug: false,
      // resources: resources,
      backend: {
        backends: [
          LocalStorageBackend,  // primary
          HttpApi               // fallback
        ],
        backendOptions: [{
          // prefix for stored languages
          prefix: 'i18next_res_',

          // expiration
          expirationTime: 7*24*60*60*1000,

          // language versions
          versions: versions,

          // can be either window.localStorage or window.sessionStorage. Default: window.localStorage
          store: window.sessionStorage
        }, {
          loadPath: '/locales/{{lng}}/{{ns}}.json' // xhr load path for my own fallback
        }]
      }
    }, (err, t) => {
      window.i18nextInitialized = true
    });
  })
